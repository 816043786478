<div [ngClass]="{
    container: true,
    'rtl-container': lang === 'ar',
    notValidToPay: !validToPay
  }">
  <div *ngIf="getUrlData?.invoice?.receiver?.logo" class="text-center">
    <img class="company-logo" [src]="getUrlData?.invoice?.receiver?.logo" alt="copmany logo" />
  </div>
  <div class="row">
    <div class="col-12">
      <p class="invoice">{{ "Invoices" | translate }}</p>
    </div>
    <div class="col-12 d-flex" *ngIf="showInvoices">
      <span class="invoice-span">{{
        "Select at least one invoice" | translate
        }}</span>
    </div>
    <div *ngIf="invoices.length > 0" class="d-flex selectAll">
      <input class="mx-2" type="checkbox" [checked]="isSelectedAll" [disabled]="!validToPay" name="pay" id="select"
        (change)="selectAll($event)" />
      <label for="select" class="rent font-weight-bold">
        {{ "Select All" | translate }}</label>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-7 col-sm-12 offset-lg-0">
      <div class="box-shadow mt-5 p-3" *ngIf="invoices == null || invoices.length < 1">
        <h1 class="main-font">
          {{ "NO CURRENT INVOICES" | translate }}
        </h1>
      </div>
      <!-- start show all invoices -->
      <div *ngFor="let invoice of invoices; let i = index">
        <div>
          <div class="main-border layout">
            <div class="main-header">
              <div class="col ar-right">
                <input [disabled]="invoice?.status.active === false || !validToPay" type="checkbox" name="pay"
                  id="check{{ i }}" [(ngModel)]="invoice.isChecked" (change)="selectInvoiceCard($event, i, invoice)"
                  [checked]="invoice?.status.checked" />
                <label for="check{{ i }}" class="rent font-weight-bold">
                  {{ invoice?.contract_type | translate }}</label><br />
              </div>
              <div class="col ar-left">
                <span class="past {{
                    past_due(invoice?.date_to) ? 'past-col' : 'due-col'
                  }}">
                  {{
                  past_due(invoice?.date_to)
                  ? ("Past Due" | translate)
                  : ("Due" | translate)
                  }}
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-12 px-5">
                <p class="main-font mid-font font-weight-bold">
                  <span class="">{{ "Payer Name" | translate }}: </span>
                  {{ invoice?.payer_name }}
                </p>
                <p class="main-font mid-font font-weight-bold">
                  <span class="">{{ "Owner Name" | translate }}: </span>
                  {{ invoice?.owner_name || '-'}}
                </p>
                <p class="main-font mid-font font-weight-bold">
                  <span class="">{{ "Unit Name" | translate }}: </span>
                  {{ invoice?.unit_name }}
                </p>
                <p class="main-font mid-font font-weight-bold">
                  <span class="">{{
                    "Contract Number" | translate
                    }}:</span>

                  {{ invoice?.contract_name }}
                </p>
                <p class="main-font mid-font font-weight-bold">
                  <span class="">{{
                    "Contract Type" | translate
                    }}:</span>

                  {{ invoice?.contract_type }}
                </p>

                <p class="main-font mid-font font-weight-bold" *ngIf="invoice?.amount_currency">
                  <span class="">{{ "Amount" | translate }}:</span>
                 <span>
                  {{
                  invoice?.amount_currency
                  }} {{invoice?.currency}}
                 </span>
                </p>
                <p class="main-font mid-font font-weight-bold" *ngIf="invoice?.amount_fees">
                  <span class="">{{ "fees_amount" | translate }}:</span>
                  {{
                  invoice?.amount_fees
                  }} {{invoice?.currency}}
                </p>
                <p class="main-font mid-font font-weight-bold" *ngIf="!isCurrencyPayment">
                  <span class="">{{ "Amount" | translate }}:</span>
                  {{
                  calculateTotal(invoice?.amount_remaining, invoice?.discount)
                  }}
                  {{ "EGP" | translate }}
                </p>
                <p class="main-font mid-font font-weight-bold" *ngIf="!isCurrencyPayment">
                  <span class="">{{ "Discount" | translate }}:</span>
                  {{ invoice?.discount || "0.00" }} {{ "EGP" | translate }}
                </p>
                <p [formGroup]="partialForm" *ngIf="
                    (partialCheckedShow && invoicesCheckedData.length - 1 === i) && !isCurrencyPayment
                  " class="main-font mid-font font-weight-bold form-group">
                  <span class="">{{ "Partial Amount" | translate }}:</span>

                  <span>
                    <input type="number" (keyup)="lastPartialChange($event, invoice)" [(ngModel)]="partial_amount"
                      [placeholder]="'0.00' | translate" formControlName="partial_amount" />
                    {{ "EGP" | translate }}
                  </span>

                  <span *ngIf="partialForm.controls.partial_amount.touched">
                    <p class="status-danger" *ngIf="
                        partialForm.controls.partial_amount.errors?.pattern
                      ">
                      {{
                      "Partial Amount should be positive number" | translate
                      }}!
                    </p>
                    <p class="status-danger" *ngIf="
                        partialForm.controls.partial_amount.value >=
                        invoice.amount_remaining
                      ">
                      {{
                      "Partial Amount should be less than Total Amount"
                      | translate
                      }}!
                    </p>
                  </span>
                </p>
                <p class="main-font mid-font font-weight-bold">
                  <span class="">{{ "Period" | translate }}</span> :
                  {{ invoice?.start_date }} -
                  {{ invoice?.end_date }}
                </p>

                <div class="row">
                  <div class="col-md-12">
                    <!-- <p class="main-font mid-font font-weight-bold">
                      <span class="">{{
                        "Owner Name" | translate
                        }}</span>
                      :
                      {{ invoice?.owner}}
                    </p> -->
                  </div>
                  <!-- <div class="col-md-12 partial" *ngIf="
                      invoicesCheckedData?.length - 1 === i ||
                      (allInvoices?.length - 1 === i &&
                        allInvoices[i].status.checked)
                    ">
                    <input [disabled]="!validToPay" [checked]="partialCheckedShow" type="checkbox" name="pay"
                      id="partial" [value]="i" (change)="onChangePartial($event, i, invoice)" />
                    <label for="partial" class="rent font-weight-bold">
                      {{ "Use partial payment" | translate }}</label>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- start payment options -->

    <div class="col-lg-5 col-sm-12 offset-lg-0 ar-text-right" *ngIf="invoices.length > 0">
      <div class="main-border px-5 pt-3">
        <p class="summary">{{ "Payment Options" | translate }} :</p>
        <div class="sec-container">
          <div class="row justify-content-between py-1 mb-2">
            <div class="col-sm-12 d-flex align-items-center">
              <div class="radio-img">
                <input [disabled]="!validToPay" type="radio" name="method" id="credit" value="credit"
                  (change)="methodChange('credit')" />
                <img class="px-2 w-50-icon" src="assets/icons/Card.svg" />
              </div>
              <label class="main-font grdlbl" for="credit">
                {{ "Credit/Debit/Meeza card" | translate }}
              </label>
            </div>
            <div class="col-sm-5 d-flex justify-content-end"></div>
          </div>
          <div class="row justify-content-between py-1 mb-2">
            <div class="col-sm-12 d-flex align-items-center">
              <div class="radio-img">
                <input [disabled]="!validToPay" type="radio" name="method" id="fawry" value="fawry"
                  (change)="methodChange('fawry')" />
                <img class="px-2 w-80-icon" src="assets/images/fawry.png" />
              </div>
              <label class="main-font grdlbl" for="fawry">
                {{ "Fawry payment" | translate }}
              </label>
            </div>
            <div class="col-sm-5 d-flex justify-content-end"></div>
          </div>
          <div class="row justify-content-between py-1 mb-2">
            <div class="col-sm-12 d-flex align-items-center">
              <div class="radio-img">
                <input [disabled]="!validToPay" type="radio" name="method" id="installment" value="installment"
                  (change)="methodChange('installment')" />
                <img class="px-2 w-50-icon" src="assets/icons/installment.svg" />
              </div>
              <label class="main-font grdlbl" for="credit">
                {{ "Pay with Installment" | translate }}
              </label>
            </div>
            <div class="col-sm-5 d-flex justify-content-end"></div>
          </div>
          <div *ngIf="payment_method === 'installment'" class="container d-flex">
            <button (click)="select_installment($event, 'contact')"
              [ngClass]="{ selected_value: install_value === 'contact' }" class="month-div w-25">
              <img class="w-100" src="../../../assets/icons/Contact Logo.svg" />
            </button>
          </div>
        </div>

        <hr />
        <p class="summary">{{ "Payment Summary" | translate }} :</p>
        <div class="container font-weight-bold">
          <div *ngIf="partialChecked || partialCheckedShow" class="row">
            <div class="col-12 text-black mid-font pl-4">
              {{ "You are about to pay" | translate }}
              <span class="col-orange">{{
                formateService.formatNum(total)
                }}</span>
              {{ "EGP" | translate }}
              {{ "out of" | translate }}
              <span class="col-orange">
                {{ formateService.formatNum(total_invoice_amount) }}
              </span>
              {{ "EGP" | translate }}
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-4 text-black mid-font pl-4">
              {{ "Subtotal" | translate }}
            </div>
            <div class="col-8 text-black mid-font text-right pr-2">
              {{ isCurrencyPayment ? subtotalFeesWithCurrency :formateService.formatNum(subtotal) }}
              {{ isCurrencyPayment ?currency :"EGP" | translate }}
            </div>
          </div>
          <div class="row mb-2" *ngIf="!isCurrencyPayment">
            <div class="col-4 text-black mid-font pl-4">
              {{ "Discount" | translate }}
            </div>
            <div class="col-8 text-black mid-font text-right pr-2">
              {{ formateService.formatNum(total_discount) }}
              {{ "EGP" | translate }}
            </div>
          </div>
          <div *ngIf="(partialChecked || partialCheckedShow) && !isCurrencyPayment" class="row mb-2">
            <div class="col-4 text-black mid-font pl-4">
              {{ "Remaining" | translate }}
            </div>
            <div class="col-8 text-black mid-font text-right pr-2">
              {{ formateService.formatNum(total_invoice_amount - total) }}
              {{ "EGP" | translate }}
            </div>
          </div>

          <div class="row mb-2" >
            <div class="col-4 text-black mid-font pl-4">
              {{ "Fees" | translate }}
            </div>
            <div class="col-8 mid-font text-black text-right pr-2">
              {{ isCurrencyPayment?feesWithCurrency: formateService.formatNum(total_fees) }} {{ isCurrencyPayment ?currency :"EGP" | translate}}
            </div>
          </div>
          <hr />
          <div class="row total">
            <div class="">
              <p class="text-white">{{ "Total Amount" | translate }}</p>
            </div>
            <div class="mid-font text-white fw-100">
              <span class="money">
                {{ isCurrencyPayment?finalTotalCurrency: formateService.formatNum(finalTotal) }}
                {{ isCurrencyPayment ?currency :"EGP" | translate }}</span>
            </div>
          </div>
          <div class="row terms">
            <!-- <div class="col-1">
                <input type="checkbox" id="terms" #terms />
              </div> -->
            <div class="col-10 d-flex">
              <input [disabled]="!validToPay" type="checkbox" value="policy" (change)="checkPolicy($event)" class="mx-2"
                id="terms" />
              <label for="terms" class="terms-label">
                <p>
                  "{{
                  "By clicking Pay Now button, you agree that you checked our "
                  | translate
                  }}
                  <a routerLink="/privacy-policy" target="_blank">{{ "Payment Policy" | translate }}"</a>
                </p>
              </label>
            </div>
          </div>
          <div class="row pt-3 checkout-btns">
            <div class="desktopview">
              <a routerLink="/" style="display: inline-block"><button class="btn text-white cancel font-weight-bold">
                  {{ "Cancel" | translate }}
                </button></a>
              <button class="btn text-white checkout font-weight-bold" (click)="checkout()" [disabled]="
                  total <= 0 ||
                  payment_method === null ||
                  !partialForm.valid ||
                  !policyChecked ||
                  (!install_value && payment_method == 'installment') ||
                  !validToPay
                ">
                {{ "Pay Now" | translate }}
              </button>
            </div>
            <div class="mobileview">
              <button class="btn text-white checkout font-weight-bold" (click)="checkout()" [disabled]="
                  total <= 0 ||
                  payment_method === null ||
                  !partialForm.valid ||
                  !policyChecked ||
                  (!install_value && payment_method == 'installment') ||
                  !validToPay
                ">
                {{ "Pay Now" | translate }}
              </button>
              <a routerLink="/"><button class="btn text-white cancel font-weight-bold">
                  {{ "Cancel" | translate }}
                </button></a>
            </div>
          </div>
        </div>
      </div>
      <div class="main-border">
        <div class="d-flex justify-content-around p-3 align-items-center">
          <p class="mid-font text-black ml-3 font-weight-bold">
            {{ "Contact an expert to support you" | translate }}
          </p>
          <a href="tel:+201096504044" class="contact-link">
            <button class="btn text-white checkout font-weight-bold">
              {{ "Contact us" | translate }}
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
